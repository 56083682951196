/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'app/theme/styles/spinner';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css';
@import 'app/theme/styles/base';
@import 'app/theme/styles/spaces';
@import 'app/theme/styles/theme';
@import 'app/theme/styles/rtl';

.alc {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  text-align: center;
}

.al-r {
  justify-content: flex-end;
}

.al-l {
  justify-content: flex-start;
}

.al-c {
  justify-content: center;
}

.text-cap {
  text-transform: capitalize;
}

#toast-container > .toast-success {
  background-color: rgb(0, 196, 0);
  box-shadow: none;
}
